import t from 'i18n';

export const MENU_ITEMS = [
	{ label: t('aboutObject'), value: 'aboutObject' },
	{ label: t('techFireWayBill.title'), value: 'wayBill' },
	{ label: t('techFire.infoAboutFire'), value: 'infoAboutFire' },
	{ label: t('techFire.infoAboutPerson'), value: 'infoAboutPerson' },
	{ label: t('techFire.distance'), value: 'distance' },
	{ label: t('techFire.firstUnit'), value: 'firstUnit' },
	{ label: t('techFire.situationToFirstDivision'), value: 'situationToFirstDivision' },
	{ label: t('techFire.timeFirstSupplyLocalizationTermination'), value: 'timeFirstSupplyLocalizationTermination' },
	{ label: t('techFire.effects'), value: 'effects' },
	{ label: t('techFire.infoAffected'), value: 'infoAffected' },
	{ label: t('techFire.resultInquiry'), value: 'resultInquiry' },
	{ label: t('techFire.workFireDep'), value: 'workFireDep' },
	{ label: t('trunk.plural'), value: 'trunks' },
	{ label: t('fireDep.chief'), value: 'chief' },
	{ label: t('techFire.organizations'), value: 'organizations' },
	{ label: t('techFire.rescued'), value: 'rescued' },
	{ label: t('techFire.evacuated'), value: 'evacuated' },
	{ label: t('techFire.addInfo'), value: 'addInfo' },
	{ label: t('techFire.calls.title'), value: 'fireCalls' },
	{ label: t('techFire.firereport'), value: 'firereport' },
];
