import React from 'react';
import FireIcon from 'img/icons/techfires/card/fire.svg';
import { ListStore, Card } from 'components';
import { Loader, Table, Column, RecordSelect, Input } from '@smartplatform/ui';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { fio } from 'client/tools';
import store from 'client/store';

import t from 'i18n';

@inject('fireStore')
@observer
export default class FireCalls extends React.Component {
	@observable calls = [];
	@observable isLoading = false;

	constructor(props) {
		super(props);
		const { fire, id, path } = this.props.fireStore;

		this.store = new ListStore({
			filters: { responsibleForCalling: true },
			path: `${path}/${id}/calls`,
			fields: {},
			model: store.model['ViewFireDepPersonnel'],
		});
		this.fire = fire;
		this.fireId = id;
		this.garrisonId = this.fire.garrisonId;
		this.init();
		this.updateFilter();
	}

	init = async () => {
		this.isLoading = true;

		// получим обзвоны
		try {
			this.calls = await store.model.TechFireCalls.find({
				where: { fireId: Number(this.fireId) },
				include: [{ relation: 'status' }],
			});
		} catch {
			this.calls = [];
		}
		// получим ранг пожара (вначале из путевых, если их нет, то из пожара)
		try {
			const res = await store.model.TechFireWayBill.find({
				where: { updatedAt: { neq: null }, fireid: Number(this.fireId) },
				order: 'updatedAt desc',
				limit: 1,
			});
			// если нет путевых листов берем ранг из пожара
			const { level } = await store.model.TechFireRank.findById((res.length > 0 && res[0].rankId !== null) ? res[0].rankId : this.fire.rankId);
			this.fireRankLevel = level;
		} catch {
			this.fireRankLevel = null;
		}
		this.fireDepsIds = await this.getFireDepsIdsByGarrisonId(this.garrisonId);
		this.updateFilter();
		this.isLoading = false;
	};

	componentDidMount() {
		this.store.setPerPage();
		this.updateFilter();
	}

	getFireDepsIdsByGarrisonId = async (id) => {
		if (!id) return [];
		const fireDeps = await store.model.ViewFireDep.find({ where: { garrisonId: id } });
		const fireDepsIds = fireDeps.map((dep) => dep.id);
		return fireDepsIds;
	};

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [] };
		filter.where.and.push({ responsibleForCalling: true });
		if (this.fireDepsIds && this.fireDepsIds.length > 0) filter.where.and.push({ fireDepId: { inq: this.fireDepsIds } });
		if (this.fireRankLevel) filter.where.and.push({ or: [{ ranklevel: { lte: this.fireRankLevel } }, { ranklevel: null }] });
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

	onChangeStatus = (row) => async (value) => {
		const record = this.calls.find((call) => call.fireId === Number(this.fireId) && call.personnelId === row.id);
		if (value) {
			const data = {
				fireId: Number(this.fireId),
				personnelId: row.id,
				statusId: value.id,
			};
			if (record !== undefined) {
				record.statusId = value.id;
				await record.save();
			} else {
				await store.model.TechFireCalls.create(data);
			}
		} else {
			if (record !== undefined) {
				const { id } = await store.model.TechFireCalls.findOne({ where: { fireId: this.fireId, personnelId: row.id } });
				if (id) {
					await store.model.TechFireCalls.deleteById(id);
				}
			}
		}
		this.init();
		this.updateFilter();
	};

	renderStatus = (r) => {
		const value = this.calls.find((call) => call.fireId === Number(this.fireId) && call.personnelId === r.id);
		return (
			<RecordSelect
				model={store.model.TechFireCallStatus}
				property='name'
				value={value ? value.status : null}
				itemsPerPage={10}
				onChange={(value) => this.onChangeStatus(r)(value)}
			/>
		);
	};

	renderCallUpdate = (r) => {
		const value = this.calls.find((call) => call.fireId === Number(this.fireId) && call.personnelId === r.id);
		const updateDate = value ? new Date(value.updatedAt).toLocaleString('ru-RU', { hour12: false }) : null;
		return <Input model={store.model.TechFireCall} property='updatedAt' value={updateDate} disabled width={170} />;
	};

	render() {
		if (this.isLoading) return <Loader />;

		const { tableProps } = this.store;

		return (
			<Card title={t('techFire.calls.title')} icon={<FireIcon />} collapsible>
				<Table {...tableProps} onRowClick={null}>
	 				<Column computed={fio} label={t('person.fullName')} width={200} />
	 				<Column relation='position' property='name' label={t('fireDepPosition.title')} width={90} />
	 				<Column property='phone' label={t('person.phone')} width={80} />
	 				<Column relation='techFireRank' property='name' label={t('techFire.calls.rank')} width={70} />
	 				<Column label={t('fireDepPersonnel.status')} width={200} computed={(r) => this.renderStatus(r)} />
	 				<Column label={t('techFire.calls.updated')} width={200} computed={(r) => this.renderCallUpdate(r)} />
	 			</Table>
			</Card>
		);
	}
}
